.ListItemHeader {
	font-family: 'TTCommonsProMedium';
	font-size: 1.5rem;
	color: #2F3628;
	margin: 0;
}

.Large {
	font-size: 1.7rem;
}
