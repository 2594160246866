.InstagramLogo {
	height: 128px;
	width: auto;
	margin-bottom: 3rem;
}

.InstaLink,
.InstaLink:hover,
.InstaLink:active {
	color: #DEE8E0;
	cursor: none;
}