.PrimaryCTA {
	background: #445D5C !important;
	border: 2px solid #445D5C !important;
	color: #F1E9D3 !important;
	padding: 0.5rem 1.5rem !important;
	border-radius: 0.4rem !important;
	white-space: nowrap;
}

.PrimaryCTA:hover {
	color: #F1E9D3 !important;
}

.XLarge {
	font-size: 1.6rem !important;
}

.PrimaryCTA.Gold{
	background: #212721 !important;
	border: 2px solid #C08329 !important;
	color: #C08329 !important;
}
