.Image {
	width: 200px;
	height: auto;
	margin-bottom: 2rem;
}

.FormWrapper {
	text-align: left;
	padding-bottom: 1rem;
	max-width: 550px;
	margin: 0 auto;
}

.EmailLink, .EmailLink:hover {
	color: #2F3628;
}