.BottleContainer{
	width:  100%;
	max-width: 400px;
	text-align: center;
	position: relative;
	margin:  0 auto;
	margin-bottom: 5.0rem;
}

.Image {
	height: 220px;
	width: auto;
}

@media only screen and (min-width: 767px) {
	.BottleContainer {
		margin-bottom: 3.0rem;
		max-width: 200px;
	}
}

@media only screen and (min-width: 992px) {
	.BottleContainer {
		max-width: 300px;
	}
}