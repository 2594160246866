/* BASIS: PHONE SCREENS */
.Hero {
	position: relative;
	height: 100vh;
	background-image: url('../../../assets/images/black-friday-mobile-bg.webp');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: right;
}

.Header {
	font-family: 'PPEditorialNewUltralight';
	text-transform: uppercase;
	margin-top: 2.25rem;
	font-size: 2rem;
}

.Header em{
	font-family: 'PPEditorialNewUltralightItalic';
	text-transform: lowercase;
}

.Tagline {
	margin-top: 1.25rem;
	font-size: 1.4rem;
	font-weight: 300;
}

.LongCopy {
	display: none;
}

.CTAContainer {
	position: absolute;
	bottom: 150px;
}

.LargeCTA {
	display: none;
}


/* BETWEEN A PHONE AND A TABLET */

@media only screen and (min-width: 500px) {

	.LongCopy {
		display: block;
	}

	.ShortCopy {
		display: none;
	}

	.LargeCTA {
		display: block;
	}

	.MediumCTA {
		display: none;
	}
}
/* TABLET/ */
@media only screen and (min-width: 768px) {
	.Hero{
		background-image: url('../../../assets/images/black-friday-bg.webp');
		background-position: -850px 0;
	}
	.CTAContainer {
		position: relative;
		margin-top: 2rem;
		bottom: 0;
	} 
}

/* BETWEEN A TABLET AND A LAPTOP*/

@media only screen and (min-width: 810px) {
	.Hero{
		background-position: -950px 0;
	}
}

@media only screen and (min-width: 925px) {
	.Hero{
		background-position: -700px 0;
	}
}

@media only screen and (min-width: 992px) {
	.Hero{
		background-position: -550px 0;
	} 
	.Header {
		margin-top: 8rem;
		font-size: 2.5rem;
	}

	.Tagline {
		margin-top: 1.8rem;
		font-size: 1.75rem;
		font-weight: 300;
	}

	.CTAContainer {
		margin-top: 5rem;
	} 
}

/* LAPTOPS */
@media only screen and (min-width: 1100px) {
	.Hero{
		background-position: -180px 0;
	} 
	.Header {
		margin-top: 8rem;
	}
}

@media only screen and (min-width: 1130px) {
	.Hero{
		background-position: -160px 0;;
	} 
}

@media only screen and (min-width: 1160px) {
	.Hero{
		background-position: -130px 0;
	} 
}

@media only screen and (min-width: 1200px) {
	.Hero{
		background-position: -120px 0;
	} 
}

/* LARGE SCREENS */
@media only screen and (min-width: 1250px) {
	.Hero{
		background-position: -80px 0;
	} 
}

@media only screen and (min-width: 1325px) {
	.Hero{
		background-position: -40px 0;
	} 
}

@media only screen and (min-width: 1400px) {
	.Hero{
		background-position: 0 0;
	} 

	.Tagline {
		max-width: 90%;
	}
}


/* PHONES ON LANDSCAPE */
@media only screen and (max-height: 550px) {
	.Hero{
		background-position: right;
	} 

	.Header {
		margin-top: 2.5rem;
		font-size: 2rem;
	}

	.Tagline {
		margin-top: 1.5rem;
		font-size: 1.4rem;
	}

	.CTAContainer {
		margin-top: 2.5rem;
	}

	.LargeCTA {
		display: none;
	}

	.MediumCTA {
		display: block;
	}
}

/* TABLETS ON LANDSCAPE */
@media only screen and (min-width: 750px) and (max-height: 768px) {
	.Hero{
		background-position: right;
	} 
}
