.CircleButton {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 16px;
	width: 16px;
	border-radius: 100%;
	border: 1px solid #fff;
	background: #fff;
	color: #2F3628;
	font-size: 0.7rem;
}

.CircleButton:hover {
	cursor: pointer;
}
