/* BASIS: PHONE SCREENS */
.Hero {
	position: relative;
	height: 100vh;
	background-image: url('../../../assets/images/mobile-background.webp');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: right;
}

.Header {
	font-family: 'PPEditorialNewUltralight';
	text-transform: uppercase;
	margin-top: 4rem;
	font-size: 2rem;
}

.Header em{
	font-family: 'PPEditorialNewUltralightItalic';
	text-transform: lowercase;
}

.Tagline {
	margin-top: 1.8rem;
	font-size: 1.4rem;
	font-weight: 300;
	max-width: 250px;
}

.LongCopy {
	display: none;
}

.CTAContainer {
	position: absolute;
	bottom: 160px;
}

.LargeCTA {
	display: none;
}

.Icon {
	height: 36px;
	width: 36px;
	opacity: 0.85;
	margin-right: 0.5rem;
}

/* BETWEEN A PHONE AND A TABLET */

@media only screen and (min-width: 500px) {
	.Hero{
		background-image: url('../../../assets/images/background.webp');
		background-position: -750px 0;
	} 

	.Header {
		margin-top: 8rem;
		font-size: 3rem;
	}

	.Tagline {
		margin-top: 1.8rem;
		font-size: 2rem;
		font-weight: 300;
		max-width: 75%;
	}

	.LongCopy {
		display: block;
	}

	.ShortCopy {
		display: none;
	}

	.CTAContainer {
		position: relative;
		margin-top: 5rem;
		bottom: 0;
	}

	.LargeCTA {
		display: block;
	}

	.MediumCTA {
		display: none;
	}
}
@media only screen and (min-width: 600px) {
	.Hero{
		background-position: -700px 0;
	} 
}

/* BETWEEN A TABLET AND A LAPTOP*/
@media only screen and (min-width: 650px) {
	.Hero{
		background-position: -650px 0;
	} 
}

@media only screen and (min-width: 925px) {
	.Hero{
		background-position: -600px 0;
	} 
}

/* LAPTOPS */
@media only screen and (min-width: 1100px) {
	.Hero{
		background-position: -500px 0;
	} 
}

@media only screen and (min-width: 1200px) {
	.Hero{
		background-position: -475px 0;
	} 
}

/* LARGE SCREENS */
@media only screen and (min-width: 1300px) {
	.Hero{
		background-position: right;
	} 
}

@media screen and (min-height: 1100px){
	.Header {
		margin-top: 15rem;
	}
}

/* PHONES ON LANDSCAPE */
@media only screen and (max-height: 550px) {
	.Hero{
		background-position: right;
	} 

	.Header {
		margin-top: 2.5rem;
		font-size: 2rem;
	}

	.Tagline {
		margin-top: 1.5rem;
		font-size: 1.4rem;
	}

	.CTAContainer {
		margin-top: 2.5rem;
	}

	.LargeCTA {
		display: none;
	}

	.MediumCTA {
		display: block;
	}
}

/* TABLETS ON LANDSCAPE */
@media only screen and (min-width: 750px) and (max-height: 768px) {
	.Hero{
		background-position: right;
	} 
}
