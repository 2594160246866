.ModalContent {
	background: #DEE8E0 !important;
	color:  #2F3628 !important;
	display: relative !important;
}

.ModalContent.BetaModal {
	background: #90AEAA !important;
}

.Close {
	display: inline-block;
	position: absolute;
	right: 30px;
}

.Close:hover{
	cursor: pointer;
}