.TastingCollection {
	background: #B7D1CD;
	display: flex;
	align-items: center;
	padding: 2rem;
	border-radius: 1rem;
	margin-bottom: 2rem;
}

.Img {
	max-width: 100%;
	min-width: 232px;
}

@media only screen and (max-width: 767px) {
	.TastingCollection {
		display: block;
	}
}