.CartMessage {
	background: #DEE8E0;
	color:  #2F3628;
	border-radius: 0.5rem;
	z-index: 100;
	padding: 1rem;
	width: 225px;
	position: absolute;
	right: 0rem;
	top: 2.5rem;
	box-shadow: 1px 1px 4px 3px rgba(0,0,0,0.3);
	-webkit-box-shadow: 1px 1px 4px 3px rgba(0,0,0,0.3);
	-moz-box-shadow: 1px 1px 4px 3px rgba(0,0,0,0.3);
}



