.SideNavItem {
	color: #2F3628 !important;
	width: 100%;
	border-radius: 0px !important;
	display: none !important;
}

.SideNavItem:first-child {
	border-radius: 0.5rem 0.5rem 0rem 0rem !important;
}

.SideNavItem:nth-child(5) {
	border-radius: 0rem 0rem 0.5rem 0.5rem !important;
}

.SideNavItem.Active,
.SideNavItem:hover{
	background-color: #B7D1CD !important;
}

.SideNavItem:hover{
	cursor: pointer;
}

@media only screen and (min-width: 768px) {
	.SideNavItem {
		display: block !important;
	}
}