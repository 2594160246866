.DollarDiscountItem {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 0.80rem;
}

.PriceWrapper{
	min-width: 50px;
	text-align: center;
}
