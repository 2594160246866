.SectionHeader {
	font-family: 'PPEditorialNewUltralight';
	text-transform: uppercase;
	font-size:  1.75rem;
	font-weight: 300;
  line-height: 1.2;
  margin: 0;
  margin-bottom: 1rem;
}

.SectionHeader.NoCapitalization {
	text-transform: capitalize;
}

.SectionHeader em {
	font-family: 'PPEditorialNewUltralightItalic';
	text-transform: capitalize;
}

@media only screen and (min-width: 768px) {
	.SectionHeader{
		font-size:  2.5rem;
		margin: 0;
	}
}