.OptionContainer {
	width:  100%;
	text-align: center;
	position: relative;
	padding: 2rem;
	margin-bottom: 3.0rem;
	background-color: #B7D1CD;
	border-radius: 0.5rem;
	position: relative;
}

.Image {
	height: 110px;
	width: auto;
}

@media only screen and (min-width: 375px) {
	.Image {
		height: 140px;
	}
}

@media only screen and (min-width: 992px) {
	.OptionContainer {
		margin-right: 2rem;
		max-width: 275px;
	}

	.OptionContainer:first-child {
		margin-left: 0;
	}

	.Image {
		height: 100px;
	}
}

@media only screen and (min-width: 1200px) {
	.OptionContainer {
		max-width: 325px;
	}

	.Image {
		height: 120px;
	}
}