/*Default*/
.LinkBtn,
.LinkBtn:active,
.LinkBtn:focus {
	padding: 0 !important;
	color: rgba(241, 233, 211, 0.85) !important;
	text-decoration: none !important;
	outline: none !important;
  box-shadow: none !important;
}

.LinkBtn:hover{
	color: rgba(241, 233, 211, 1) !important;
}

/*Gold*/
.LinkBtn.Gold,
.LinkBtn.Gold:active,
.LinkBtn.Gold:focus {
	color: rgba(192, 131, 41, 0.85) !important;
}

.LinkBtn.Gold:hover{
	color: rgba(192, 131, 41, 1) !important;
}
