.Banner {
	border-radius: 1rem;
	background-size: cover;
  background-repeat: no-repeat;
  padding: 5rem 4rem 5rem 24rem;
}

.MobileImage {
	display: none;
}


@media only screen and (max-width: 1199px) {
	.Banner {
		padding-left: 18rem;
	}
}

@media only screen and (max-width: 991px) {
	.Banner {
		padding-left: 10rem;
		background-position: -100px 0;
	}
}

@media only screen and (max-width: 767px) {
	.Banner {
		padding-left: 6rem;
		background-position: -200px 0;
	}
}

@media only screen and (max-width: 458px) {
	.Banner {
		background: none !important;
		padding: 0 2rem;
		text-align: center;
	}

	.MobileImage {
		display: block;
		width: 100%;
		max-width: 275px;
		height: auto;
		margin: 0 auto;
		margin-bottom: 1rem;
	}
}


