.ListItem {
	margin-right: 1.5rem;
}

.ListItem:last-child{
	margin-right: 0;
}

@media only screen and (max-width: 991px) {
  .Navbar {
  	max-width: 300px;
  	margin-left: auto;
  }

  .Navbar .ListItem{
  	margin-right: 60px;
  	margin-bottom: 0.5rem;
  	text-align: right;
  }
}