.SignInButton {
	width: 100%;
	max-width: 400px;
	display: flex !important;
	align-items: center;
	margin:  0 auto 1rem auto;
}

.Icon {
	height: 24px;
	width: 25px;
	background-size: cover;
	margin-right: 0.75rem;
}

.GoogleButton {
	background: #fff !important;
	color:  #757575 !important;;
	border-color: #fff !important;;
}

.GoogleButton:hover,
.GoogleButton:focus,
.GoogleButton:active {
	border-color: #eee !important;
}

.FacebookButton {
	background: #1877F2 !important;
	color:  #fff !important;;
	border-color: #1877F2 !important;;
}

.FacebookButton:hover,
.FacebookButton:focus,
.FacebookButton:active {
	border-color: #0C76FF !important;
}

.EmailButton {
	background: #697A57 !important;
	color:  #fff !important;;
	border-color: #697A57 !important;;
}

.EmailButton:hover,
.EmailButton:focus,
.EmailButton:active {
	border-color: #5F6F4E !important;
}
