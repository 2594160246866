.PlanContainer{
	width:  100%;
	max-width: 300px;
	text-align: center;
	position: relative;
	margin:  0 auto;
	margin-bottom: 5.0rem;
}

.BadgeWrapper{
	position: absolute;
	width:  100%;
	top: -45px;
	text-align: center;
}

.Badge {
	display: inline-block;
	font-size: 0.65rem;
	color:  #fff;
	background-color: #7851A9;
	border-radius: 0.25rem;
	padding:  0.1rem 0.3rem;
}

.Image {
	height: 220px;
	width: auto;
}

.Price {
	font-family: 'TTCommonsProRegular';
	font-size: 2.25rem;
	margin-bottom: 2.5rem;
}

@media only screen and (min-width: 767px) {
	.PlanContainer {
		margin-bottom: 3.0rem;
		max-width: 200px;
	}
}

@media only screen and (min-width: 992px) {
	.PlanContainer {
		max-width: 300px;
	}
}