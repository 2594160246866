.Hero {
	height: 100vh;
	max-height: 900px;
	background-image: url('../../../assets/images/academy/academy-background.webp');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: -240px 0;
	display: flex;
	align-items: center;
}

.Header {
	font-family: 'PPEditorialNewUltralight';
	font-size: 2rem;
	margin-bottom: 1.5rem;
}

.Tagline {
	font-size: 1.2rem;
	margin-bottom: 5rem;
}

@media only screen and (min-width: 576px) {
  .Hero {
		background-position: -200px 0;
  }
}

@media only screen and (min-width: 650px) {
  .Hero {
		background-position: -150px 0;
  }
}

@media only screen and (min-width: 768px) {
  .Hero {
		background-position: -125px 0;
  }

  .Header{
  	font-size: 2.5rem;
  } 

  .Tagline {
		font-size: 1.3rem;
	}
}

@media only screen and (min-width: 925px) {
  .Hero {
		background-position: -90px 0;
  }
}

@media only screen and (min-width: 1050px) {
  .Hero {
		background-position: -30px 0;
  }
}

@media only screen and (min-width: 1150px) {
  .Hero {
		background-position: 0 0;
  }
}

