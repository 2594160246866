.TransparentBg {
	height: 100vh;
	width: 100vw;
	background: #000;
	opacity: 40%;
	position: fixed;
	top:  0;
	z-index: 10;
	display: none;
}

.Drawer {
	padding: 3rem 2rem;
	width: 100%;
	min-width: 325px;
	position: fixed;
	top:  0;
	right:  0;
	z-index: 100;
	height: 100vh;
	background: #DEE8E0;
  overflow: auto;
	display: none;
}

.Close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.Close:hover{
  cursor: pointer;
}

@media only screen and (min-width: 550px) {
  .Drawer {
    width: 85%;
  }
}

@media only screen and (min-width: 750px) {
  .Drawer {
    width: 70%;
  }
}

@media only screen and (min-width: 1024px) {
  .Drawer {
    width: 50%;
  }
}

@media only screen and (min-width: 1200px) {
  .Drawer {
    width: 45%;
  }
}

/* SLIDE ANIMATIONS - SLIDE IN */

.SlideInRight {
	display: block;
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

/* SLIDE ANIMATIONS - SLIDE OUT */

.SlideOutRight {
  display: block;
	-webkit-animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@-webkit-keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}


