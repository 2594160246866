.Card {
	max-width: 500px;
	border-radius: 1rem;
	background-color: #B7D1CD;
	margin: 0 auto;
	margin-bottom: 1rem;
}

.Image {
	border-radius: 1rem 1rem 0 0;
	width: 100%;
	height: auto;
}

.Content {
	text-align: center;
	padding: 1.5rem 1.5rem;
}

@media only screen and (min-width: 992px) {
	.Card {
		max-width: 100%;
		display: flex;
	}

	.Image {
		border-radius: 1rem 0 0 1rem;
		width: 33%;
		max-width: 368px;
	}

	.Content {
		text-align: left;
	}
}