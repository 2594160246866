/*Default*/
.Button,
.Button:active,
.Button:visited {
	background-color: rgba(241, 233, 211, 0.9) !important;
	border: none !important;
	color:  #445D5C !important;
	font-weight: 600 !important;
	padding: 0.75rem 1.25rem !important;
	border-radius: 0.3rem !important;
}

.Button:hover {
	background-color: rgba(241, 233, 211, 1) !important;
}

/*Gold*/
.Button.Gold,
.Button.Gold:active,
.Button.Gold:visited {
	background-color: rgba(192, 131, 41, 0.85) !important;
	color:  #212721 !important;
}

.Button.Gold:hover {
	background-color: rgba(192, 131, 41, 1) !important;
	color:  #212721 !important;
}
