/* Default */
.NavLink {
	padding: 0 !important;
	color: rgba(241, 233, 211, 0.85) !important;
	text-decoration: none !important;
}

.NavLink:hover,
.NavLink.Active {
	color: rgba(241, 233, 211, 1) !important;
}


/*Gold colored*/
.NavLink.Gold {
	color: rgba(192, 131, 41, 0.85) !important;
}

.NavLink.Gold:hover,
.NavLink.Gold.Active{
	color: rgba(192, 131, 41, 1) !important;
}