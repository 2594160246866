.Alert {
	z-index: 100;
	position: fixed;
	display: none;
	align-items: center;
	border-radius: 0.5rem;
	padding:  0.75rem;
  padding-right: 1rem;
	bottom: 3rem;
	left: 3rem;
	box-shadow: 3px 3px 20px 0px rgba(0,0,0,0.75);
	-webkit-box-shadow: 3px 3px 20px 0px rgba(0,0,0,0.75);
	-moz-box-shadow: 3px 3px 20px 0px rgba(0,0,0,0.75);
}

.SlideIn {
	display: flex;
	-webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.SlideOut {
	display: flex;
	-webkit-animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

/* Stolen from Bootstrap */ 
.Success { 
	background-color: #5E7975;
	border: 1px solid #5E7975;
	color: #F1E9D3 !important;
}

.Image {
	width: 30px;
	height: auto;
	margin-right: 0.5rem;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-11-9 8:14:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-11-9 15:56:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
}
@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
}


