.Wrapper {
	position: relative !important;
}

.IconWrapper {
	height: 36px;
	width:  36px;
	position:  relative;
	margin-left: 1.25rem;
}

.ShiftUp {
	top: 3px;
}

.HasIcon {
	margin-right: 0.5rem;
}

.Icon {
	width: 100%;
	height: auto;
	opacity: 75%;
}

.Badge {
	background-color: rgba(241, 233, 211, 1) !important;
}

.Badge.GoldBadge {
	background-color: #C08329 !important;
} 

.IconWrapper:hover {
	cursor: pointer;
}

.IconWrapper:hover .Icon{
	opacity: 100%;
}
