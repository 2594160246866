.Image {
	width: 280px;
	height: auto;
	margin-bottom: 2rem;
}

@media only screen and (min-width: 768px) {
	.Image {
		width: 200px;
		height: auto;
	}
}