.OutlineButton,
.OutlineButton:active{
	color: #445D5C !important;
	border-color: #445D5C !important;
	border-radius: 0.25rem !important;
	padding: 0.5rem 0.9rem !important;
	min-width: 190px;
	outline: none !important;
  box-shadow: none !important;
  border-size: 1px !important;
}

.OutlineButton:hover,
.OutlineButton:focus {
	color: #F1E9D3 !important;
	background-color: #445D5C !important;
	border-color: #445D5C !important;
	outline: none !important;
  box-shadow: none !important;
  border-size: 1px !important;
}