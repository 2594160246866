.CartItem {
	margin-bottom: 1.25rem;
}

.CartItem:last-child {
	margin-bottom: 0;	
}

.ItemInfoWrapper {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: right;
}

.ImageWrapper {
	display: none;
	height: 107px;
	width:  60px;
	margin-right: 1rem;
}

.Image {
	height: auto;
	width: 100%;
	min-width: 35px;
}

.BottleImage {
	height: 107px;
	width: auto;
	min-width: 35px;
}

.StrikedPrice {
	text-decoration: line-through;
	color: #21252980;
}

.ItemInfo {
	flex-grow: 2;
	min-width: 180px;
}

.AdjustQuantityWrapper {
	display: flex;
	justify-content: end;
	flex-grow: 1;
	min-width: 60px;
}

.Price {
	min-width: 50px;
	text-align: center;
	display: none;
}

@media only screen and (min-width: 375px) {
	.ImageWrapper {
		display: flex;
		align-items: center;
	}
}

@media only screen and (min-width: 475px) {
  .ItemInfoWrapper {
  	padding-right: 2rem;
  }

  .CartItem {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.ItemInfoWrapper {
		justify-content: flex-start;
	}

	.Price {
		display: block;
	}

	.SmallTotalPrice{
		display: none;
	}
}