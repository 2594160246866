.CopyUrlWrapper {
	display: flex;
	justify-content: space-between;
	background: #eee;
	align-items: center;
	border: 1px solid #ced4da;
	border-radius: 0.375rem;
	padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  max-width: 500px;
}

.Link {
	opacity: 0.7;
}

.Button {
	font-size: 0.75rem;
	border-radius: 0.375rem;
	padding: 0.375rem 0.75rem;
	background: #fff;
}

.Button:hover {
	background: #697A57;
	color:  #fff;
	cursor: pointer;
}

.Active {
	background: #697A57;
	color:  #fff;
}

