.TreeImage {
	width: 100%;
	height: auto;
	margin-bottom: 0.75rem;
} 

.FoundersImage {
	width: 120px;
	height: auto;
	margin-right: 1rem;
}

.Signature {
	font-size: 1.2rem;
	font-family: 'PPEditorialNewUltralightItalic';
	margin-bottom: 0;
}

.PurchasingHeader {
	margin-bottom: 2rem;
	font-size: 1.85rem;
	font-family: 'PPEditorialNewUltralight';
}

.PurchasingHeader .Tagline {
	text-transform: uppercase;
}

.PurchasingHeader .Tagline em{
	text-transform: lowercase;
	font-family: 'PPEditorialNewUltralightItalic';
}