.Hero {
	height: 100vh;
	max-height: 900px;
	background-image: url('../../../assets/images/background-1.webp');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 0;
	display: flex;
	align-items: center;
}

.Header {
	font-family: 'PPEditorialNewUltralight';
	text-transform: uppercase;
	font-size: 3rem;
	margin-bottom: 1.5rem;
}

.Header em{
	font-family: 'PPEditorialNewUltralightItalic';
	text-transform: lowercase;
}

.Tagline, .Name, .Title {
	font-size: 1.4rem;
	font-weight: 300;
}

.Tagline {
	margin-bottom: 2rem;
}

.Name, .Title {
	margin-bottom: 0;
	line-height: 1.2;
}

.CTA,
.CTA:active,
.CTA:focus {
	display: flex !important;
	align-items: center !important;
	margin-top: 6rem;
	padding: 0 !important;
	text-decoration: none !important;
	color:  #445D5C !important;
	font-size: 1.4rem !important;
	font-weight: 300 !important;
	outline: none !important;
  box-shadow: none !important;
}

.Icon {
	height: 36px;
	width: 36px;
	opacity: 0.85;
	margin-right: 0.5rem;
}

.CTA:hover {
	text-decoration: none;
}

.CTA:hover .Icon {
	opacity: 1;
}

@media only screen and (max-width: 1700px) {
  .Hero {
		background-position: -100px 0;
  }
}

@media only screen and (max-width: 1500px) {
  .Hero {
  	background-position: -150px 0;
  }
}

@media only screen and (max-width: 1280px) {
  .Hero {
  	background-position: -250px 0;
  }

  .Header{
  	font-size: 2.5rem;
  }

  .Tagline {
  	font-size: 1.3rem;
  }

  .CTA,
	.CTA:active,
	.CTA:focus {
		font-size: 1.3rem !important;	
	}
}

@media only screen and (max-width: 992px) {
  .Hero {
  	background-position: -300px 0;
  }
}


@media only screen and (max-width: 768px) {
  .Hero {
  	background-position: -525px 0;
  }
}

@media only screen and (max-width: 574px) {
  .Header{
  	font-size: 2rem;
  }

  .Tagline, .Name, .Title {
  	font-size: 1.2rem;
  }

  .CTA,
	.CTA:active,
	.CTA:focus {
		font-size: 1.2rem !important;	
	}
}