.GoldTogglerBtn {
  border-color: #c08329 !important;
}

.GoldTogglerBtn:focus {
  box-shadow:0 0 0 0.1rem #c08329 !important;
}

.GoldTogglerIcon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28192,131, 41, 0.8%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
}

