.Card {
	max-width: 500px;
	border-radius: 1rem;
	background-color: #B7D1CD;
	margin: 0 auto;
	height: 100%;
}

.Image {
	border-radius: 1rem 1rem 0 0;
	width: 100%;
	height: auto;
}

.Title {
	font-weight: 600;
}