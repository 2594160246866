.Hero {
	/*position: relative;*/
	color:  rgba(241, 233, 211, 0.85);
	height: 100vh;
	max-height: 900px;
	background: #445D5C;
}

/*
.BottleWrapper{
	overflow: hidden;
	top: 0;
	right: -20rem;
	position: absolute;
	height: 100%;
	width: auto;
}

.Bottle {
	height: 125%;
	width: auto;
}
*/

.Header {
	font-family: 'PPEditorialNewUltralight';
	text-transform: uppercase;
	margin-top: 13rem;
	font-size: 3rem;
}

.Header em{
	font-family: 'PPEditorialNewUltralightItalic';
	text-transform: lowercase;
}

.Tagline {
	margin-top: 2rem;
	font-size: 1.4rem;
	font-weight: 300;
}

.CTA,
.CTA:active,
.CTA:focus {
	display: flex !important;
	align-items: center !important;
	margin-top: 10rem;
	padding: 0 !important;
	text-decoration: none !important;
	color:  rgba(241, 233, 211, 0.85) !important;
	font-size: 1.4rem !important;
	font-weight: 300 !important;
	outline: none !important;
  box-shadow: none !important;
}

.Icon {
	height: 36px;
	width: 36px;
	opacity: 0.85;
	margin-right: 0.5rem;
}

.ShortCopy {
	display: none;
}

.CTA:hover {
	text-decoration: none;
	color: rgba(241, 233, 211, 1) !important;
}

.CTA:hover .Icon {
	opacity: 1;
}

@media only screen and (max-width: 1280px) {
  .Header{
  	font-size: 2.5rem;
  }

  .Tagline {
  	font-size: 1.3rem;
  }

  .CTA,
	.CTA:active,
	.CTA:focus {
		font-size: 1.3rem !important;	
	}
}


@media only screen and (max-width: 850px) {
  .Hero {
  	background-position: -100px 0 !important;
  }
}

@media only screen and (max-width: 574px) {
  .Hero {
  	background-position: -350px 0 !important;
  }

  .Header{
  	font-size: 2rem;
  }

  .Tagline {
  	font-size: 1.2rem;
  }

  .CTA,
	.CTA:active,
	.CTA:focus {
		font-size: 1.2rem !important;	
	}

	.ShortCopy {
		display: block;
	}

	.LongCopy {
		display: none;
	}
}