.BottomCTA{
	background: #B3B995;
	padding:  3rem 0;
}

.CTACopy {
	font-size:  2rem;
	font-weight: 300;
  line-height: 1.2;
  margin: 0;
  margin-bottom: 1rem;
}

@media only screen and (min-width: 768px) {
	.CTACopy {
		font-size:  2.5rem;
		margin: 0;
		margin-right: 2rem;
	}
}

