.Membership {
	padding: 1.5rem 2rem 1rem 2rem;
	border-radius: 1rem;
	border:  1px solid #C8DDCC;
	margin-bottom: 1rem;
}

.ProductInfoWrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #C8DDCC;
	padding-bottom: 1rem;
}

.ImageWrapper {
	display: flex;
	align-items: center;
	height: 80px;
	width:  80px;
	margin-right: 1rem;
}

.Image {
	height: 100%;
	width: auto;
	min-width: 17px;
}

.Quantity {
	display: none;
	padding: 0rem 1rem;
}

.TotalPrice {
	display: none;
}

.Small {
	font-weight: 300;
	font-size: 0.6rem;
	margin-bottom: 0;
}

.BottomWrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 1rem;
}

@media only screen and (min-width: 425px) {
	.SmallScreenQuantity, 
	.SmallScreenTotalPrice {
		display: none;
	}

	.Quantity,
	.TotalPrice {
		display: block;
	}

	.ImageWrapper {
		justify-content: center;
	}

}