.OrderItem {
	margin-bottom: 1.25rem;
}

.OrderItem:last-child {
	margin-bottom: 0;	
}

.ItemInfo {
	min-width: 180px;
}

.ImageWrapper {
	display: flex;
	align-items: center;
	height: 60px;
	width:  34px;
	margin-right: 1rem;
}

.SingleBottleImage {
	height: 100%;
	width: auto;
	min-width: 17px;
}

.MultipleBottlesImage {
	height: auto;
	width: 100%;
	min-width: 17px;
}

.Price {
	display: none;
	text-align: right;
}

.StrikedPrice {
	text-decoration: line-through;
	color: #21252980;
}

@media only screen and (min-width: 376px) {
  .OrderItem {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.Price {
		display: block;
	}

	.SmallTotalPrice{
		display: none;
	}
}