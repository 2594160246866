.PromoBanner {
	display: inline-flex;
	align-items: center;
	text-align: left;
	background-color: #B7D1CD;
	border-radius: 0.375rem;
	padding: 0.5rem 0.75rem ;
}

.Icon {
	margin-right: 0.5rem;
	height: 24px;
	width: auto;
}

.PromoCopy {
	margin-bottom: 0;
}