.InfoBanner {
	background: #B7D1CD;
	padding: 1rem;
	border-radius: 0.35rem;
	margin-bottom: 1.75rem;
}

.IconWrapper {
	width: 28px;
	margin-right: 0.5rem;
	text-align: center;
}

.Icon {
	height: 20px;
	width: auto;
}