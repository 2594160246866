.LeadP{
	font-family: 'TTCommonsProMedium';
	font-size: 1.35rem;
	color: #2F3628;
}

.Bold{
	font-weight: 500;
} 

.MbSmall {
	margin-bottom: 0.15rem;
}