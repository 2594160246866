.InnerCard {
	background-color: #DEE8E0;
	border-radius: 1rem;
	padding: 1rem;
}

.Sign {
	width: 60px;
	height: auto;
	margin-bottom: 0.75rem;
}

.Title {
	font-size: 1.15rem;
	font-weight: 600;
}

@media only screen and (min-width: 768px) {
	.InnerCard {
		display: flex;
		text-align: left;
	}
	.Sign {
		width: 48px;
		height: 44px;
		margin-right: 1rem;
	}
}