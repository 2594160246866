.BtnLink {
	color: #445D5C !important;
	text-decoration: none !important;
	padding: 0 !important;
}

.BtnLink:hover,
.BtnLink:active, 
.BtnLink:focus {
	outline: none !important;
  box-shadow: none !important;
}

.NoWrap {
	white-space: nowrap !important;
}

.Small{
	font-size: 0.7rem !important;
} 