.BottomContact{
	background: #B3B995;
	padding:  3rem 0;
}

.ContactHeader {
	font-size: 2rem;
	font-family: 'PPEditorialNewUltralightItalic';
	margin-bottom: 0;
}

.Image{
	height: 6rem;
	width: auto;
}

.Email, 
.Email:focus,
.Email:active,
.Email:hover {
	color: #445D5C !important;
	text-decoration: none !important;
}


