/* FONTS */

/* TT Commons Pro */
@font-face {
  font-family: 'TTCommonsProRegular';   
  src: local('TTCommonsProRegular'),
    url('../../assets/fonts/TTCommonsProRegular.ttf') format("truetype");
}

@font-face {
  font-family: 'TTCommonsProMedium';   
  src: local('TTCommonsProMedium'),
    url('../../assets/fonts/TTCommonsProMedium.ttf') format("truetype");
}

@font-face {
  font-family: 'TTCommonsProDemiBold';   
  src: local('TTCommonsProDemiBold'),
    url('../../assets/fonts/TTCommonsProDemiBold.ttf') format("truetype");
}

/* PP Editorial New */
@font-face {
  font-family: 'PPEditorialNewUltralight';   
  src: local('PPEditorialNew-Ultralight'),
    url('../../assets/fonts/PPEditorialNew-Ultralight.ttf') format("truetype");
}

@font-face {
  font-family: 'PPEditorialNewUltralightItalic';   
  src: local('PPEditorialNew-UltralightItalic'),
    url('../../assets/fonts/PPEditorialNew-UltralightItalic.ttf') format("truetype");
}

/* STYLES */
.Global {
	font-family: 'TTCommonsProRegular', sans-serif !important;
	background: #DEE8E0;
	color:  #212721;
}